<template>
  <div id="app">
    <h1>Mesurador de Soroll</h1>
    <div v-if="volume !== null" class="noise-meter__markers">
      <div>
        <p class="noise-meter__result" :style="{ 'font-size': '8em', color: volumeColor }"> {{ volume }} dB</p>
      </div>
      <div id="semaforo">
        <div :class="{ luz: true, rojo: this.volume > 100 }"></div>
        <div :class="{ luz: true, amarillo: this.volume > 70 && this.volume < 100 }"></div>
        <div :class="{ luz: true, verde: this.volume < 70 }"></div>
      </div>
    </div>
    <div>
      <button @click="startListening" :disabled="listening">Iniciar</button>
      <button @click="stopListening" :disabled="!listening">Parar</button>
    </div>
    <div class="noise-meter__info">
      <div class="noise-meter__text">
        <h3>Com funciona aquest Mesurador de Soroll en línia?</h3>
        <p> Simplement has d'acceptar l'ús del micròfon per part del navegador la primera vegada que accedeixis a l'eina, i el mesurador de soroll començarà a funcionar utilitzant el micròfon de l'ordinador.</p>
        <p> En funció del nivell de soroll detectat, el resultat canviarà de color. Per sota de 30dB, blau; per sota de 70db, verd; per sota de 100dB, groc; i més de 100dB, vermell.</p>
      </div>
    <div>
      <img src="grafic-soroll.jpeg"/>
    </div>
  </div>
  <div class="noise-meter__logo">
    <span>Desenvolupat per:</span>
    <img src="LogoNubalticColor.png"/>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listening: false,
      volume: null,
      audioContext: null,
      analyser: null,
      dataArray: null,
      status: 'rojo'
    };
  },
  methods: {
    async startListening() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        this.analyser = this.audioContext.createAnalyser();
        const microphone = this.audioContext.createMediaStreamSource(stream);
        microphone.connect(this.analyser);

        this.analyser.fftSize = 256;
        const bufferLength = this.analyser.frequencyBinCount;
        this.dataArray = new Uint8Array(bufferLength);

        this.listening = true;
        setInterval(() => this.updateLoop(), 500); // Actualizar cada 500ms
      } catch (error) {
        console.error('Error al acceder al micrófono: ', error);
      }
    },
    stopListening() {
      if (this.audioContext) {
        this.audioContext.close();
        this.audioContext = null;
        this.analyser = null;
        this.dataArray = null;
        this.listening = false;
        this.volume = null;
      }
    },
    updateLoop() {
      if (this.analyser) {
        this.analyser.getByteFrequencyData(this.dataArray);
        const volume = this.dataArray.reduce((acc, val) => acc + val, 0) / this.dataArray.length;
        this.volume = volume.toFixed(2);

        // Definir colores según el nivel de sonido
        if (volume < 30) {
          // blau
          this.volumeColor = '#42b3f5';
        } else if (volume < 70) {
          this.volumeColor = '#23ad25';
        } else if (volume < 100) {
          this.volumeColor = '#ebe534';
        } else {
          this.volumeColor = '#d61e1e';
        }
      }
    },
  }
};
</script>

<style>
#app {
  text-align: center;
  margin-top: 60px;
  font-family: Calibri, 'Trebuchet MS', sans-serif
}

button {
  margin: 10px;
  padding: 10px;
  font-size: 16px;
}

.noise-meter__result{
  font-size: 20px;
  margin: 2rem;
}

.noise-meter__info {
  padding: 1rem 16rem;
  display: flex;
  align-items: center;
  h3 {
    font-size: 1.6rem;
    font-weight: 600;
  }
  p {
    font-size: 1.2rem;
  }
}

.noise-meter__text {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.noise-meter__markers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

img { 
    max-width: 800px
}

.noise-meter__logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 1rem;
  right: 1rem;

  span {
    margin-bottom: .3rem;
    font-size: .7rem;
    color: #192F4D;
  }
  img {
    width: 8rem;
  }
}

@media (max-width: 1424px) {
  .noise-meter__info {
    padding: 1rem 6rem;
  }
  h3 {
    font-size: 1.2rem;
  }
  img { 
    max-width: 500px;
  }
}

@media (max-width: 992px) {
  img { 
    width: 100%
  }
  .noise-meter__info {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
  }
  h3 {
    font-size: 1.4rem;
  }
  p {
    font-size: 1.1rem;
  }  
}

#semaforo {
      width: 100px;
      height: 300px;
      border-radius: 10px;
      background-color: #585a5c;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }

    .luz {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 1px;
      background-color: #fff;
    }

    .rojo {
      background-color: #d61e1e;
    }

    .amarillo {
      background-color: #ebe534;
    }

    .verde {
      background-color: #23ad25;
    }
</style>
